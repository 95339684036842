import React from 'react'
import styled, { keyframes } from 'styled-components'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Contact from '../components/Contact'
import Transition from '../components/Transition'
import { Helmet } from 'react-helmet'

const forfaits = [
  {
    title: 'Forfait de base',
    price: '400 € / mois',
    services: [
      '10 heures de support par mois',
      'Gestion des e-mails',
      'Planification de rendez-vous',
      'Suivi des tâches simples',
    ],
  },
  {
    title: 'Forfait intermédiaire',
    price: '800 € / mois',
    services: [
      '20 heures de support par mois',
      'Gestion des e-mails et prise de rendez-vous',
      'Organisation de documents',
      'Assistance dans la gestion de projets',
      'Rédaction de comptes rendus et de rapports',
    ],
  },
  {
    title: 'Forfait avancé',
    price: '1 600 € / mois',
    services: [
      '40 heures de support par mois',
      'Tous les services des forfaits précédents',
      'Gestion des réseaux sociaux',
      'Création de contenus (articles, newsletters)',
      'Assistance à la facturation et gestion des factures',
    ],
  },
  {
    title: 'Forfait au projet',
    price: 'À partir de 500 € (en fonction du projet)',
    services: [
      "Assistance pour des projets spécifiques (ex. préparation d'un événement, création d'une base de données)",
      "Tarification sur la base des heures travaillées ou d'un prix fixe selon le projet",
    ],
  },
  {
    title: "Forfait à l'heure",
    price: '40 € / heure',
    services: [
      "Facturation à l'heure pour des tâches ponctuelles",
      "Flexibilité d'utilisation selon les besoins",
    ],
  },
  {
    title: "Forfait d'urgence",
    price: '50 € / heure (en dehors des heures habituelles)',
    services: [
      'Assistance rapide pour des tâches urgentes',
      'Disponibilité le week-end ou après les heures de bureau',
    ],
  },
]

const DiscountNote =
  'Sur SITE : 10€ supplémentaires au tarif horaire de base + application des frais kilométriques. Remises : -10% pour un engagement de 6 mois, -15% pour un engagement de 12 mois.'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const ForfaitsSection = styled.section`
  background-color: #faf3e9;
  padding: 6rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const SectionTitle = styled.h2`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 3rem;
  margin-bottom: 2rem;
  animation: ${fadeIn} 1s ease-out;
`

const ForfaitsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  max-width: 1200px;
  width: 100%;
`

const ForfaitCard = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: calc(33.333% - 2rem);
  animation: ${fadeIn} 1s ease-out;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`

const ForfaitTitle = styled.h3`
  font-family: 'Radley', sans-serif;
  color: #ea9d6c;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: color 0.3s ease;

  &:hover {
    color: #9b4819;
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -5px;
    width: 0;
    height: 3px;
    background-color: #ea9d6c;
    transition: width 0.3s ease;
    transform: translateX(-50%);
  }

  &:hover::after {
    width: 100%;
  }
`

const ForfaitPrice = styled.p`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  font-weight: bold;
  background: linear-gradient(90deg, #ea9d6c, #9b4819);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`

const ForfaitServices = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Radley', sans-serif;
  color: #333;
  font-size: 1rem;
  text-align: left;
  line-height: 1.6;
`

const ForfaitServiceItem = styled.li`
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
  transition: color 0.3s ease, transform 0.3s ease;

  &::before {
    content: '✔';
    position: absolute;
    left: 0;
    color: #ea9d6c;
    font-size: 1.2rem;
  }

  &:hover {
    color: #9b4819;
    transform: translateX(5px);
  }
`

const DiscountText = styled.p`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 1rem;
  margin-top: 2rem;
  max-width: 800px;
  text-align: center;
  background-color: rgba(234, 157, 108, 0.1);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
`

const Subtitle = styled.p`
  font-family: 'Radley', sans-serif;
  color: #ea9d6c;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 800px;
  text-align: center;
  line-height: 1.5;
  font-weight: 400;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    background-color: #9b4819;
    margin: 0 auto 1rem;
    border-radius: 2px;
  }
`

const CTAButton = styled.a`
  font-family: 'Radley', sans-serif;
  background-color: #ea9d6c;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #d88c5b;
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
`

const NosForfaits = () => {
  return (
    <>
      <Helmet>
        <title>MelOffice - Nos Forfaits</title>
      </Helmet>
      <Header />
      <ForfaitsSection>
        <SectionTitle>Nos Forfaits</SectionTitle>
        <Subtitle>
          Choisissez le forfait qui correspond le mieux à vos besoins et libérez
          votre temps pour ce qui compte vraiment.
        </Subtitle>
        <ForfaitsGrid>
          {forfaits.map((forfait, index) => (
            <ForfaitCard key={index}>
              <ForfaitTitle>{forfait.title}</ForfaitTitle>
              <ForfaitServices>
                {forfait.services.map((service, i) => (
                  <ForfaitServiceItem key={i}>{service}</ForfaitServiceItem>
                ))}
              </ForfaitServices>
            </ForfaitCard>
          ))}
        </ForfaitsGrid>
        <DiscountText>{DiscountNote}</DiscountText>
        <CTAButton href="#contact">Demandez votre Devis sur Mesure</CTAButton>
      </ForfaitsSection>
      <Transition />
      <Contact />
      <Footer />
    </>
  )
}

export default NosForfaits
