import React from 'react'
import styled, { keyframes } from 'styled-components'
import { FaCheckCircle } from 'react-icons/fa'

const nebula = keyframes`
  0%, 100% { transform: translate(0, 0); }
  33% { transform: translate(2%, 1%); }
  66% { transform: translate(-1%, -1%); }
`

const HeroContainer = styled.section`
  background-color: #faf3e9;
  padding: 8rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
`

const NebulaEffect = styled.div`
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  background: radial-gradient(
      circle at 20% 35%,
      rgba(155, 72, 25, 0.15) 0%,
      transparent 50%
    ),
    radial-gradient(
      circle at 75% 44%,
      rgba(234, 157, 108, 0.15) 0%,
      transparent 40%
    ),
    radial-gradient(
      circle at 46% 52%,
      rgba(155, 72, 25, 0.1) 0%,
      transparent 30%
    ),
    radial-gradient(
      circle at 50% 50%,
      rgba(234, 157, 108, 0.1) 0%,
      transparent 60%
    );
  filter: blur(40px);
  opacity: 0.7;
  animation: ${nebula} 20s ease-in-out infinite;
`

const HeroContent = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`

const Title = styled.h1`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 4rem;
  margin-bottom: 1rem;
  line-height: 1.2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`

const Subtitle = styled.p`
  font-family: 'Radley', sans-serif;
  color: #ea9d6c;
  font-size: 1.75rem;
  margin-bottom: 2rem;
`

const CTAButton = styled.a`
  background-color: #9b4819;
  color: #faf3e9;
  font-family: 'Radley', sans-serif;
  font-size: 1.2rem;
  padding: 0.8rem 2rem;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-block;
  margin-top: 2rem;
  box-shadow: 0 4px 10px rgba(155, 72, 25, 0.3);

  &:hover {
    background-color: #ea9d6c;
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(155, 72, 25, 0.4);
  }
`

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
  }
`

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 1.1rem;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 0.5rem 1rem;
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  svg {
    margin-right: 0.5rem;
    color: #ea9d6c;
  }
`

const Hero = () => {
  return (
    <HeroContainer>
      <NebulaEffect />
      <HeroContent>
        <Title>Votre Assistante Freelance de Confiance</Title>
        <Subtitle>Libérez votre temps, boostez votre productivité</Subtitle>
        <FeatureList>
          <FeatureItem>
            <FaCheckCircle /> Gestion administrative
          </FeatureItem>
          <FeatureItem>
            <FaCheckCircle /> Organisation d'agenda
          </FeatureItem>
          <FeatureItem>
            <FaCheckCircle /> Recherche et mise en place d'outils d'organisation
            et d'optimisation
          </FeatureItem>
          <FeatureItem>
            <FaCheckCircle /> Support client
          </FeatureItem>
          <FeatureItem>
            <FaCheckCircle /> Gestion de projets
          </FeatureItem>
        </FeatureList>
        <CTAButton href="#contact">
          Travaillons ensemble dès maintenant
        </CTAButton>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero
