import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'

const NotFoundSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #faf3e9;
  text-align: center;
`

const Title = styled.h1`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 10rem;
  margin-bottom: 1rem;
`

const Subtitle = styled.h2`
  font-family: 'Radley', sans-serif;
  color: #ea9d6c;
  font-size: 2rem;
  margin-bottom: 2rem;
`

const Message = styled.p`
  font-family: 'Radley', sans-serif;
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 2rem;
`

const HomeLink = styled(Link)`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border: 2px solid #9b4819;
  border-radius: 5px;
  margin-bottom: 120px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #9b4819;
    color: #faf3e9;
  }
`

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 - Page introuvable</title>
      </Helmet>
      <Header />
      <NotFoundSection>
        <Title>404</Title>
        <Subtitle>Page non trouvée</Subtitle>
        <Message>Désolé, la page que vous recherchez n'existe pas.</Message>
        <HomeLink to="/">Retour à l'accueil</HomeLink>
      </NotFoundSection>
      <Footer />
    </>
  )
}

export default NotFound
