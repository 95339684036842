import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import {
  FaEnvelope,
  FaPhone,
  FaLinkedin,
  FaInstagram,
  FaFacebook,
} from 'react-icons/fa'

const FooterContainer = styled.footer`
  background-color: #9b4819;
  color: #faf3e9;
  padding: 3rem 2rem;
  font-family: 'Radley', sans-serif;
`

const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const FooterSection = styled.div`
  flex: 1;
  min-width: 200px;
  margin-bottom: 2rem;
  text-align: center;

  @media (min-width: 769px) {
    text-align: left;
  }
`

const FooterTitle = styled.h3`
  color: #faf3e9;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`

const FooterLink = styled(Link)`
  color: #faf3e9;
  text-decoration: none;
  display: block;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #ea9d6c;
  }
`

const HashFooterLink = styled(HashLink)`
  color: #faf3e9;
  text-decoration: none;
  display: block;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #ea9d6c;
  }
`

const ContactInfo = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 0.5rem;
  }

  @media (min-width: 769px) {
    justify-content: flex-start;
  }
`

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;

  @media (min-width: 769px) {
    justify-content: flex-start;
  }
`

const SocialIcon = styled.a`
  color: #faf3e9;
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #ea9d6c;
  }
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  @media (min-width: 769px) {
    justify-content: flex-start;
  }
`

const Logo = styled.img`
  height: 90px; /* Adjust the height as needed */
  margin-right: 1rem; /* Space between logo and text */
`

const LogoText = styled.span`
  font-size: 2rem; /* Adjust the font size as needed */
  color: #faf3e9; /* Match the footer text color */
`

const Copyright = styled.div`
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #ea9d6c;
`

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterLink to="/">
            <LogoContainer>
              <Logo src="/logo-mel.png" alt="Mel'Office Logo" />
              <LogoText>Mel'Office</LogoText>
            </LogoContainer>
          </FooterLink>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Navigation</FooterTitle>
          <HashFooterLink to="/#home">Accueil</HashFooterLink>
          <FooterLink to="/nos-forfaits">Nos Forfaits</FooterLink>
          <FooterLink to="/nos-valeurs">Nos Valeurs</FooterLink>
          <HashFooterLink to="/#contact">Contact</HashFooterLink>
          <FooterLink to="/mentions-legales">Mentions Légales</FooterLink>
          <FooterLink to="/conditions-generales-vente">
            Conditions Générales de Vente
          </FooterLink>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Contact</FooterTitle>
          <ContactInfo>
            <FaEnvelope /> contact.meloffice@gmail.com
          </ContactInfo>
          <ContactInfo>
            <FaPhone /> +33 6 10 35 57 07
          </ContactInfo>
        </FooterSection>
      </FooterContent>
      <Copyright>
        © {new Date().getFullYear()} Mel'Office. Tous droits réservés.
        <FooterLink as="a" href="https://netfactory.fr" target="_blank">
          Création de NetFactory
        </FooterLink>
      </Copyright>
    </FooterContainer>
  )
}

export default Footer
