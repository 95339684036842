import React from 'react'
import styled from 'styled-components'
import { FaQuoteLeft, FaLinkedin, FaEnvelope } from 'react-icons/fa'

const AboutSection = styled.section`
  background-color: #faf3e9;
  padding: 6rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AboutContainer = styled.div`
  max-width: 1200px;
  display: flex;
  gap: 4rem;
  align-items: center;

  @media (max-width: 968px) {
    flex-direction: column;
  }
`

const ImageContainer = styled.div`
  flex: 1;
  position: relative;
`

const ClientImage = styled.img`
  width: 100%;
  max-width: 400px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
`

const Accent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid #ea9d6c;
  border-radius: 15px;
  top: 20px;
  left: 20px;
  z-index: -1;
`

const ContentContainer = styled.div`
  flex: 1;
`

const Name = styled.h2`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 2.5rem;
  margin-bottom: 1rem;
`

const Title = styled.h3`
  font-family: 'Radley', sans-serif;
  color: #ea9d6c;
  font-size: 1.5rem;
  margin-bottom: 2rem;
`

const Bio = styled.p`
  font-family: 'Radley', sans-serif;
  color: #333;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`

const Quote = styled.blockquote`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 1.2rem;
  font-style: italic;
  margin: 2rem 0;
  padding-left: 2rem;
  border-left: 4px solid #ea9d6c;
  position: relative;
`

const QuoteIcon = styled(FaQuoteLeft)`
  color: #ea9d6c;
  font-size: 2rem;
  position: absolute;
  top: -10px;
  left: -10px;
`

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
`

const SocialLink = styled.a`
  color: #9b4819;
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #ea9d6c;
  }
`

const About = () => {
  return (
    <AboutSection id="about">
      <AboutContainer>
        <ImageContainer>
          <ClientImage src="/melanie.webp" alt="Mélanie Blaise" />
          <Accent />
        </ImageContainer>
        <ContentContainer>
          <Name>Mélanie Blaise</Name>
          <Title>Assistante Freelance Professionnelle</Title>
          <Bio>
            Avec plus de 13 ans d'expérience en tant qu'assistante polyvalente
            dans divers secteurs, je suis titulaire d'un BTS assistante de
            gestion PME-PMI à référentiel européen. Ma passion est d'aider les
            entrepreneurs, les freelances, les petites, moyennes et grandes
            entreprises, ainsi que les particuliers à optimiser leur
            productivité et atteindre leurs objectifs. Ma polyvalence et mon
            attention aux détails me permettent de m'adapter rapidement à
            différents domaines et besoins spécifiques. Je me démarque par ma
            capacité à proposer des actions concrètes pour améliorer la gestion
            administrative de mes clients.
          </Bio>
          <Quote>
            <QuoteIcon />
            Mon objectif est de vous libérer du temps pour que vous puissiez
            vous concentrer sur ce qui compte vraiment : faire croître votre
            entreprise.
          </Quote>
          <SocialLinks>
            <SocialLink href="mailto:contact.meloffice@gmail.com">
              <FaEnvelope />
            </SocialLink>
          </SocialLinks>
        </ContentContainer>
      </AboutContainer>
    </AboutSection>
  )
}

export default About
