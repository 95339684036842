import React from 'react'
import styled from 'styled-components'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'

const LegalSection = styled.section`
  background-color: #faf3e9;
  padding: 5rem 1rem;
`

const Container = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem 1rem;
  max-width: 800px;
  width: 90%;
  margin: auto;
  text-align: left;
`

const SectionTitle = styled.h1`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`

const SubTitle = styled.h2`
  font-family: 'Radley', sans-serif;
  color: #ea9d6c;
  font-size: 1.8rem;
  margin-bottom: 1rem;
`

const Paragraph = styled.p`
  font-family: 'Radley', sans-serif;
  color: #333;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`

const Link = styled.a`
  color: #9b4819;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #ea9d6c;
  }
`

const MentionsLegales = () => {
  return (
    <>
      <Helmet>
        <title>MelOffice - Mentions Légales</title>
      </Helmet>
      <Header />
      <LegalSection>
        <SectionTitle>Mentions Légales</SectionTitle>
        <Container>
          <SubTitle>Éditeur du site</SubTitle>
          <Paragraph>
            <strong>MEL'OFFICE</strong>
          </Paragraph>
          <Paragraph>36 RUE DE LA RONGERE, 71380 EPERVANS</Paragraph>
          <Paragraph>Nom : Mélanie Blaise</Paragraph>
          <Paragraph>SIREN : 978416477</Paragraph>
          <Paragraph>Email : contact@meloffice.fr</Paragraph>
          <Paragraph>Téléphone : 01 23 45 67 89</Paragraph>

          <SubTitle>Créateur du site</SubTitle>
          <Paragraph>
            <strong>NetFactory</strong>
          </Paragraph>
          <Paragraph>SIREN : 980621445</Paragraph>
          <Paragraph>
            Site web :{' '}
            <Link
              href="https://netfactory.fr"
              target="_blank"
              rel="noopener noreferrer"
            >
              netfactory.fr
            </Link>
          </Paragraph>

          <SubTitle>Hébergement</SubTitle>
          <Paragraph>Nom de l'hébergeur : Vercel</Paragraph>
          <Paragraph>
            Site web :{' '}
            <Link
              href="https://vercel.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              vercel.com
            </Link>
          </Paragraph>

          <SubTitle>Conditions d'utilisation</SubTitle>
          <Paragraph>
            L'utilisation de ce site implique l'acceptation pleine et entière
            des conditions générales d'utilisation décrites ci-après. Ces
            conditions d'utilisation sont susceptibles d'être modifiées ou
            complétées à tout moment.
          </Paragraph>

          <SubTitle>Propriété intellectuelle</SubTitle>
          <Paragraph>
            Tous les éléments accessibles sur le site, y compris les textes,
            images, graphismes, logo, icônes, sons, logiciels, sont protégés par
            les droits de propriété intellectuelle du titulaire du site.
          </Paragraph>

          <SubTitle>Limitations de responsabilité</SubTitle>
          <Paragraph>
            MEL'OFFICE ne pourra être tenu responsable des dommages directs et
            indirects causés au matériel de l'utilisateur, lors de l'accès au
            site meloffice.fr.
          </Paragraph>
        </Container>
      </LegalSection>
      <Footer />
    </>
  )
}

export default MentionsLegales
