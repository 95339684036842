import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { FaQuoteLeft, FaChevronLeft, FaChevronRight } from 'react-icons/fa'

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`

const TestimonialsContainer = styled.section`
  background-color: #faf3e9;
  padding: 6rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h2`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 3rem;
  margin-bottom: 3rem;
  text-align: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 100px;
    height: 3px;
    background-color: #ea9d6c;
    transform: translateX(-50%);
  }
`

const TestimonialCard = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  padding: 2rem;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  animation: ${fadeIn} 0.5s ease-out forwards, ${float} 6s ease-in-out infinite;

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`

const QuoteIcon = styled(FaQuoteLeft)`
  color: #ea9d6c;
  font-size: 2rem;
  position: absolute;
  top: -1rem;
  left: -1rem;
`

const TestimonialText = styled.p`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`

const TestimonialAuthor = styled.p`
  font-family: 'Radley', sans-serif;
  color: #ea9d6c;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: right;
`

const TestimonialCompany = styled.span`
  display: block;
  font-weight: normal;
  font-size: 0.9rem;
`

const NavigationButton = styled.button`
  background-color: #9b4819;
  color: #faf3e9;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 10px;

  &:hover {
    background-color: #ea9d6c;
    transform: scale(1.1);
  }
`

const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

const testimonials = [
  {
    text:
      'Un enthousiasme vivant et encourageant. Une collaboration absolument satisfaisante. Mélanie a eu un suivi rigoureux, professionnel et une bienveillance pour nos clients en gérant notre service après vente. Le tout avec un véritable enthousiasme.',
    author: 'Shopify',
    company: 'Plateforme de vente',
  },
  {
    text:
      'Une professionnelle, simple et rapide. Sa compréhension rapide est vraiment inspirante. Vous apprendrez sûrement plein de choses fascinantes à travailler aux côtés de Mélanie. Vous pouvez lui faire confiance.',
    author: 'Calamine',
    company: "Gérant d'un garage",
  },
  {
    text:
      'Professionnalisme Exemplaire : "Mélanie a été un atout précieux pour notre équipe. Son professionnalisme et son organisation irréprochable ont grandement facilité la gestion de nos projets. Toujours ponctuelle et réactive, elle anticipe nos besoins et s\'assure que tout soit en ordre. Je recommande vivement ses services !"',
    author: 'Jean François',
    company: 'Immobilier',
  },
  {
    text:
      'Je vous remercie de votre investissement à la Région. Vous avez su vous intégrer et appréhender différents postes et dossiers avec rapidité et professionnalisme, ce dont je vous remercie.',
    author: 'Eric',
    company: 'Collectivités',
  },
  {
    text:
      'Fiabilité et Efficacité : "J\'ai eu le plaisir de travailler avec Mélanie pendant plusieurs mois, et je ne peux que louer son efficacité et sa fiabilité. Elle gère toutes les tâches administratives avec une aisance remarquable et n\'hésite pas à proposer des améliorations pour optimiser notre organisation. Sa bonne humeur et son enthousiasme sont un vrai plus dans notre environnement de travail !"',
    author: 'Stéphanie',
    company: 'Entreprise de transport',
  },
  {
    text:
      "Excellente Communication : \"Mélanie est une assistante administrative exceptionnelle. Sa capacité à communiquer clairement et à s'adapter aux besoins de l'équipe en fait une collaboratrice de choix. Elle a toujours une solution à chaque problème et sait gérer les priorités avec brio. Grâce à elle, notre workflow s'est considérablement amélioré. Je la recommande sans hésitation !\"",
    author: 'Richard',
    company: 'Entreprise Industrielle',
  },
  {
    text:
      "Une écoute bienveillante, adaptation rapide. J'ai eu le plaisir de collaborer avec Mélanie en tant qu'assistante et je ne regrette pas du tout. Elle a su gérer notre partie administrative qui demandait exigence et action rapide. Bravo à vous!",
    author: 'Claire',
    company: 'Propriétaire de conciergerie Chalet',
  },
]

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length)
  }

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + testimonials.length) % testimonials.length,
    )
  }

  return (
    <TestimonialsContainer>
      <Title>Ce que disent nos clients</Title>
      <TestimonialCard>
        <QuoteIcon />
        <TestimonialText>{testimonials[currentIndex].text}</TestimonialText>
        <TestimonialAuthor>
          {testimonials[currentIndex].author}
          <TestimonialCompany>
            {testimonials[currentIndex].company}
          </TestimonialCompany>
        </TestimonialAuthor>
      </TestimonialCard>
      <NavigationContainer>
        <NavigationButton onClick={prevTestimonial}>
          <FaChevronLeft />
        </NavigationButton>
        <NavigationButton onClick={nextTestimonial}>
          <FaChevronRight />
        </NavigationButton>
      </NavigationContainer>
    </TestimonialsContainer>
  )
}

export default Testimonials
