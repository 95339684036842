import React from 'react'
import styled from 'styled-components'

const PartnersContainer = styled.div`
  background-color: #faf3e9;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const PartnersTitle = styled.h2`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: #ea9d6c;
    margin: 1rem auto 0;
  }
`

const PartnersGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  max-width: 1200px;
  width: 100%;
`

const PartnerCard = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem 2.3rem;
  width: calc(33.333% - 2rem);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    width: calc(50% - 2rem);
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`

const PartnerLogo = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
`

const PartnerLink = styled.a`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;

  &:hover {
    color: #ea9d6c;
  }
`
const Description = styled.p`
  font-family: 'Radley', sans-serif;
  color: #666; // Couleur gris pour la description
  font-size: 0.9rem; // Taille de police plus petite
  margin-top: 0.5rem; // Espace au-dessus de la description
  line-height: 1.4; // Hauteur de ligne pour une meilleure lisibilité
`

const Partenariats = () => {
  return (
    <PartnersContainer>
      <PartnersTitle>Nos Partenaires</PartnersTitle>
      <PartnersGrid>
        <PartnerCard>
          <PartnerLogo src="/images/netfactory.png" alt="NetFactory" />
          <PartnerLink
            href="https://netfactory.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            NetFactory
          </PartnerLink>
          <Description>
            Concepteur de Sites Web et Référencement SEO
          </Description>
        </PartnerCard>
        <PartnerCard>
          <PartnerLogo src="/images/packeat.png" alt="Pack Eat" />
          <PartnerLink
            href="https://www.pack-eat.fr/references/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pack Eat
          </PartnerLink>
          <Description>
            Concepteur et fabriquant d'emballages alimentaires 100% comestibles
          </Description>
        </PartnerCard>
        <PartnerCard>
          <PartnerLink
            href="https://www.linkedin.com/in/sabrina-bazi-91a84a174?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sabrina Bazi
          </PartnerLink>
          <Description>Fondatrice COFI & BF Consulting</Description>
        </PartnerCard>
      </PartnersGrid>
    </PartnersContainer>
  )
}

export default Partenariats
