import React from 'react'
import styled from 'styled-components'

const ExperienceContainer = styled.section`
  background-color: #9b4819cf;
  padding: 6rem 2rem;
  position: relative;
  overflow: hidden;
`

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const Title = styled.h2`
  font-family: 'Radley', serif;
  color: #fff;
  font-size: 3.5rem;
  margin-bottom: 4rem;
  text-align: center;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: #ea9d6c;
    margin: 1rem auto 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`

const ExperienceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const ExperienceCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);

  &:hover {
    transform: translateY(-10px) rotate(1deg);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    border-color: rgba(0, 0, 0, 0.1);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transition: opacity 0.3s ease;
    opacity: 0;
    pointer-events: none;
  }

  &:hover::before {
    opacity: 1;
  }

  & > * {
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  &:hover > * {
    transform: scale(1.02);
    opacity: 0.95;
  }
`

const ExperienceTitle = styled.h3`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
`

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4rem;
`

const CTAButton = styled.a`
  font-family: 'Radley', sans-serif;
  background-color: #ea9d6c;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #d88c5b;
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
`

const experiences = [
  'Immobilier',
  'Bâtiment',
  'Médical',
  'Agence de mannequin',
  'Séjour linguistique - voyage',
  'Avocat',
  'Conciergerie',
  'Événements (Festivals, sportives, salon)',
  'Agence de sécurité',
  'Assistante personnelle',
  'Collectivité',
  'Hospitalier',
  'Industrie',
  'Agence Intérimaire',
  'Transport',
]

const Experience = () => {
  return (
    <ExperienceContainer>
      <Container>
        <Title>Nos Expériences Professionnelles</Title>
        <ExperienceGrid>
          {experiences.map((exp, index) => (
            <ExperienceCard key={index}>
              <ExperienceTitle>{exp}</ExperienceTitle>
            </ExperienceCard>
          ))}
        </ExperienceGrid>
        <CTAContainer>
          <CTAButton href="#contact">
            Échangeons en toute simplicité sur votre projet
          </CTAButton>
        </CTAContainer>
      </Container>
    </ExperienceContainer>
  )
}

export default Experience
