import React from 'react'
import styled, { keyframes } from 'styled-components'

const TransitionContainer = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #faf3e9;
  position: relative;
  overflow: hidden;
  padding: 10px 0 60px 0;

  @media (max-width: 768px) {
    height: 100px;
    padding: 60px 0;
  }

  @media (max-width: 480px) {
    height: 80px;
  }
`

const float = keyframes`
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(5deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
`

const Shape = styled.div`
  position: absolute;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  animation: ${float} 5s ease-in-out infinite;
  opacity: 0.8;

  @media (max-width: 768px) {
    transform: scale(0.8);
  }

  @media (max-width: 480px) {
    transform: scale(0.6);
  }
`

const Circle = styled(Shape)`
  width: 80px;
  height: 80px;
`

const Square = styled(Shape)`
  width: 70px;
  height: 70px;
  border-radius: 15%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Triangle = styled(Shape)`
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 70px solid ${({ color }) => color};
  background-color: transparent;
`

const Transition = () => {
  return (
    <TransitionContainer>
      <Circle
        color="#9B4819"
        style={{ left: '10%', animationDuration: '6s' }}
      />
      <Square
        color="#EA9D6C"
        style={{ left: '30%', top: '60%', animationDuration: '7s' }}
      />
      <Triangle
        color="#9B4819"
        style={{ left: '50%', animationDuration: '8s' }}
      />
      <Circle
        color="#EA9D6C"
        style={{ left: '70%', top: '30%', animationDuration: '7s' }}
      />
      <Square
        color="#9B4819"
        style={{ left: '85%', animationDuration: '6s' }}
      />
    </TransitionContainer>
  )
}

export default Transition
