import React from 'react'
import styled, { keyframes } from 'styled-components'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Transition from '../components/Transition'
import { Helmet } from 'react-helmet'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const ValeursSection = styled.section`
  background-color: #faf3e9;
  padding: 6rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const SectionTitle = styled.h2`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 3rem;
  margin-bottom: 0.1rem;
  animation: ${fadeIn} 1s ease-out;
`

const ValueCategory = styled.div`
  max-width: 800px;
  margin-bottom: 4rem;
  animation: ${fadeIn} 1s ease-out;
  animation-delay: 0.3s;
  animation-fill-mode: both;
`

const ValueTitle = styled.h3`
  font-family: 'Radley', sans-serif;
  color: #ea9d6c;
  font-size: 2rem;
  margin-bottom: 1rem;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -5px;
    width: 50px;
    height: 4px;
    background-color: #9b4819;
    transform: translateX(-50%);
  }
`

const ValueList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const ValueItem = styled.li`
  font-family: 'Radley', sans-serif;
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
`

const Subtitle = styled.p`
  font-family: 'Radley', sans-serif;
  color: #ea9d6c;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  animation: ${fadeIn} 1s ease-out;
`

const Valeurs = () => {
  return (
    <>
      <Helmet>
        <title>MelOffice - Nos Valeurs</title>
      </Helmet>
      <Header />
      <ValeursSection>
        <SectionTitle>Nos Valeurs</SectionTitle>
        <Subtitle>
          Découvrez nos engagements envers l'environnement, l'entraide et
          l'éthique.
        </Subtitle>

        <ValueCategory>
          <ValueTitle>Nos valeurs écologiques</ValueTitle>
          <ValueList>
            <ValueItem>
              Écoresponsabilité : Nous sommes pleinement engagés à réduire notre
              empreinte carbone à travers plusieurs initiatives concrètes...
            </ValueItem>
            <ValueItem>
              Télétravail durable : Concernant les déplacements, nous
              privilégions les transports en commun et le covoiturage...
            </ValueItem>
            <ValueItem>
              Partenariats locaux : Nous collaborons avec des entreprises
              locales qui partagent nos valeurs écologiques...
            </ValueItem>
            <ValueItem>
              Pratiques de communication eco-friendly : En matière de
              fournitures, nous utilisons des produits en papier recyclé...
            </ValueItem>
          </ValueList>
        </ValueCategory>
        <Transition />

        <ValueCategory>
          <ValueTitle>Nos Valeurs d'Entraide et d'Aide</ValueTitle>
          <ValueList>
            <ValueItem>
              Solidarité et altruisme : Nous créons des liens avec des
              professionnels et nous offrons notre aide dans des projets
              collaboratifs...
            </ValueItem>
            <ValueItem>
              Écoute Active empathique : Nous prenons le temps d’écouter les
              besoins de nos clients...
            </ValueItem>
            <ValueItem>
              Partage de Connaissances : Nous transmettons nos compétences et
              notre expertise via nos ateliers...
            </ValueItem>
            <ValueItem>
              Flexibilité : Nous sommes disponibles pour répondre aux besoins
              variés de nos clients...
            </ValueItem>
            <ValueItem>
              Engagement communautaire : S'impliquer dans des projets qui
              améliorent la vie des membres de la communauté...
            </ValueItem>
            <ValueItem>
              Responsabilité Sociale : S'engager à travailler avec des clients
              et partenaires qui partagent des valeurs éthiques...
            </ValueItem>
          </ValueList>
        </ValueCategory>
        <Transition />

        <ValueCategory>
          <ValueTitle>Nos valeurs humaines et éthiques</ValueTitle>
          <ValueList>
            <ValueItem>
              Fiabilité : Être une personne sur qui nos clients peuvent
              compter...
            </ValueItem>
            <ValueItem>
              Intégrité : Agir avec honnêteté et transparence dans toutes les
              interactions...
            </ValueItem>
            <ValueItem>
              Respect : Traiter chaque client, collaborateur et partenaire avec
              dignité...
            </ValueItem>
            <ValueItem>
              Empathie : Comprendre les défis et les besoins uniques de chaque
              client...
            </ValueItem>
            <ValueItem>
              Excellence : S'efforcer de fournir un travail de la plus haute
              qualité...
            </ValueItem>
            <ValueItem>
              Adaptabilité : Flexible et ouvert au changement, capable de
              s'ajuster aux besoins...
            </ValueItem>
            <ValueItem>
              Collaboration : Travailler en partenariat avec les clients pour
              atteindre des objectifs communs...
            </ValueItem>
            <ValueItem>
              Confidentialité : Assurer la protection des informations sensibles
              des clients...
            </ValueItem>
            <ValueItem>
              Créativité : Proposer des solutions innovantes et efficaces aux
              problèmes...
            </ValueItem>
            <ValueItem>
              Professionnalisme : Maintenir un comportement éthique et
              respectueux...
            </ValueItem>
          </ValueList>
        </ValueCategory>
      </ValeursSection>
      <Footer />
    </>
  )
}

export default Valeurs
