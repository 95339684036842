import React from 'react'
import styled from 'styled-components'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'

const CGVSection = styled.section`
  background-color: #faf3e9;
  padding: 5rem 1rem;
`

const Container = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem 1rem;
  max-width: 800px;
  width: 90%;
  margin: auto;
  text-align: left;
`

const SectionTitle = styled.h1`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`

const SubTitle = styled.h2`
  font-family: 'Radley', sans-serif;
  color: #ea9d6c;
  font-size: 1.8rem;
  margin-bottom: 1rem;
`

const Paragraph = styled.p`
  font-family: 'Radley', sans-serif;
  color: #333;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`

const ConditionsGeneralesVente = () => {
  return (
    <>
      <Helmet>
        <title>MelOffice - Conditions Générales de Vente</title>
      </Helmet>
      <Header />
      <CGVSection>
        <SectionTitle>Conditions Générales de Vente</SectionTitle>
        <Container>
          <SubTitle>Préambule</SubTitle>
          <Paragraph>
            Les présentes conditions générales de vente s'appliquent à toutes
            les transactions conclues par le biais du site internet
            meloffice.fr. Est considérée comme client toute personne physique ou
            morale réalisant auprès de MEL'OFFICE une commande validée.
          </Paragraph>

          <SubTitle>Produits et services</SubTitle>
          <Paragraph>
            MEL'OFFICE propose des services d'assistance administrative et de
            gestion à distance. Les caractéristiques essentielles des services
            sont décrites sur le site meloffice.fr. Les photographies et
            descriptions des services n'ont pas de valeur contractuelle.
          </Paragraph>

          <SubTitle>Tarifs</SubTitle>
          <Paragraph>
            Les prix des services sont indiqués en euros toutes taxes comprises
            (TTC). MEL'OFFICE se réserve le droit de modifier ses prix à tout
            moment, mais les services seront facturés sur la base des tarifs en
            vigueur au moment de l'enregistrement de la commande.
          </Paragraph>

          <SubTitle>Responsabilité</SubTitle>
          <Paragraph>
            MEL'OFFICE ne saurait être tenue pour responsable de l'inexécution
            du contrat en cas d'indisponibilité du service dû à un cas de force
            majeure.
          </Paragraph>

          <SubTitle>Données personnelles</SubTitle>
          <Paragraph>
            MEL'OFFICE s'engage à respecter la confidentialité des données
            personnelles communiquées par le client sur le site et à les traiter
            dans le respect de la loi Informatique et Libertés du 6 janvier
            1978.
          </Paragraph>

          <SubTitle>Litiges</SubTitle>
          <Paragraph>
            Les présentes conditions générales de vente sont soumises à la loi
            française. En cas de litige, les parties s'engagent à rechercher une
            solution amiable avant d'engager toute action judiciaire.
          </Paragraph>
        </Container>
      </CGVSection>
      <Footer />
    </>
  )
}

export default ConditionsGeneralesVente
