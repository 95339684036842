import React, { useState } from 'react'
import styled from 'styled-components'
import {
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaLinkedin,
  FaInstagram,
  FaFacebook,
} from 'react-icons/fa'

const ContactContainer = styled.section`
  background-color: #faf3e9;
  padding: 8rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 8rem 0;
  }
`

const Title = styled.h2`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 3.5rem;
  margin-bottom: 3rem;
  text-align: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 100px;
    height: 3px;
    background-color: #ea9d6c;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }
`

const ContactContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 4rem;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 968px) {
    flex-direction: column;
  }
`

const ContactForm = styled.form`
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: 1000px) {
    width: 90%;
  }
`

const Label = styled.label`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  margin-bottom: 0.5rem;
`

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: 2px solid #ea9d6c;
  border-radius: 10px;
  font-family: 'Radley', sans-serif;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #9b4819;
    box-shadow: 0 0 0 3px rgba(155, 72, 25, 0.1);
  }
`

const TextArea = styled.textarea`
  width: 100%;
  padding: 1rem;
  border: 2px solid #ea9d6c;
  border-radius: 10px;
  font-family: 'Radley', sans-serif;
  font-size: 1rem;
  resize: vertical;
  min-height: 150px;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #9b4819;
    box-shadow: 0 0 0 3px rgba(155, 72, 25, 0.1);
  }
`

const SubmitButton = styled.button`
  background-color: #9b4819;
  color: #faf3e9;
  font-family: 'Radley', sans-serif;
  font-size: 1.1rem;
  padding: 1rem 2rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: flex-start;

  &:hover {
    background-color: #ea9d6c;
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`

const ContactInfo = styled.div`
  flex: 1;
  background-color: #9b4819;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Radley', sans-serif;
  color: #faf3e9;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;

  svg {
    margin-right: 1rem;
    font-size: 1.5rem;
    color: #ea9d6c;
  }
`

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
`

const SocialIcon = styled.a`
  color: #faf3e9;
  font-size: 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    color: #ea9d6c;
    transform: translateY(-3px);
  }
`

const Map = styled.iframe`
  width: 100%;
  height: 300px;
  border: none;
  border-radius: 10px;
  margin-top: 1rem;
`

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  })

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { name, email, subject, message } = formData
    const mailtoLink = `mailto:contact.meloffice@gmail.com?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\n\nMessage: ${message}`,
    )}`
    window.location.href = mailtoLink
    setFormData({ name: '', email: '', subject: '', message: '' })
  }

  return (
    <ContactContainer id="contact">
      <Title>Contactez-nous</Title>
      <ContactContent>
        <ContactForm onSubmit={handleSubmit}>
          <InputGroup>
            <Label htmlFor="name">Nom</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </InputGroup>
          <InputGroup>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </InputGroup>
          <InputGroup>
            <Label htmlFor="subject">Sujet</Label>
            <Input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </InputGroup>
          <InputGroup>
            <Label htmlFor="message">Message</Label>
            <TextArea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </InputGroup>
          <SubmitButton type="submit">Envoyer</SubmitButton>
        </ContactForm>
        <ContactInfo>
          <div>
            <InfoItem>
              <FaEnvelope />
              contact.meloffice@gmail.com
            </InfoItem>
            <InfoItem>
              <FaPhone />
              +33 6 10 35 57 07
            </InfoItem>
          </div>
          <div>
            <Map
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44436.294676775!2d4.8833333!3d46.6666667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f3c2b5c7c65c0d%3A0x4093cafcbe7c6e0!2sEpervans%2071380!5e0!3m2!1sfr!2sfr!4v1630000000000!5m2!1sfr!2sfr"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </ContactInfo>
      </ContactContent>
    </ContactContainer>
  )
}

export default Contact
