import React from 'react'
import styled, { keyframes } from 'styled-components'
import { FaClock, FaChartLine, FaLightbulb, FaHandshake } from 'react-icons/fa'

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const WhySection = styled.section`
  background-color: #9b4819cf;
  padding: 6rem 0;
  position: relative;
  overflow: hidden;
`

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`

const Title = styled.h2`
  font-family: 'Radley', sans-serif;
  color: #fff;
  font-size: 3rem;
  margin-bottom: 4rem;
  text-align: center;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: #ea9d6c;
    margin: 1rem auto 0;
  }
`

const ReasonsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`

const ReasonItem = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  animation: ${fadeIn} 0.8s ease-out forwards;
  opacity: 0;
  animation-delay: ${(props) => props.delay}s;

  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
`

const IconContainer = styled.div`
  background-color: #ea9d6c;
  color: #ffffff;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin-right: 2rem;
  flex-shrink: 0;
`

const ReasonContent = styled.div`
  flex: 1;
`

const ReasonTitle = styled.h3`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`

const Description = styled.p`
  font-family: 'Radley', sans-serif;
  color: #333333;
  font-size: 1rem;
  line-height: 1.6;
`

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4rem;
`

const CTAButton = styled.a`
  font-family: 'Radley', sans-serif;
  background-color: #ea9d6c;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #d88c5b;
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
`

const reasons = [
  {
    icon: <FaClock />,
    title: 'Gagnez du temps précieux',
    description:
      'Déléguez vos tâches administratives et concentrez-vous sur le cœur de votre activité. Optimisez votre emploi du temps et boostez votre efficacité.',
  },
  {
    icon: <FaChartLine />,
    title: 'Augmentez votre productivité',
    description:
      'Bénéficiez de compétences professionnelles pour rationaliser vos processus. Atteignez vos objectifs plus rapidement et avec moins de stress.',
  },
  {
    icon: <FaLightbulb />,
    title: "Profitez d'une expertise polyvalente",
    description:
      'Accédez à un large éventail de compétences adaptées à vos besoins spécifiques. De la gestion de projet à la communication, nous avons les solutions.',
  },
  {
    icon: <FaHandshake />,
    title: 'Flexibilité et adaptabilité',
    description:
      "Ajustez facilement les services en fonction de l'évolution de votre entreprise. Une solution sur mesure qui grandit avec vous.",
  },
]

const WhyAssistant = () => {
  return (
    <WhySection>
      <Container>
        <Title>Pourquoi choisir une assistante indépendante ?</Title>
        <ReasonsList>
          {reasons.map((reason, index) => (
            <ReasonItem key={index} delay={0.2 * (index + 1)}>
              <IconContainer>{reason.icon}</IconContainer>
              <ReasonContent>
                <ReasonTitle>{reason.title}</ReasonTitle>
                <Description>{reason.description}</Description>
              </ReasonContent>
            </ReasonItem>
          ))}
        </ReasonsList>
        <CTAContainer>
          <CTAButton href="#contact">Contactez-moi dès maintenant</CTAButton>
        </CTAContainer>
      </Container>
    </WhySection>
  )
}

export default WhyAssistant
