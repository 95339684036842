import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom' // Import Link
import { HashLink } from 'react-router-hash-link'
import { FaBars, FaTimes } from 'react-icons/fa'

const HeaderContainer = styled.header`
  background-color: #9b4819;
  padding: 0.1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  height: 70px;
  margin-right: 1rem;
`

const LogoText = styled.span`
  font-size: 1.5rem;
  color: #faf3e9;
  font-family: 'Radley', sans-serif;
`

const NavMenu = styled.nav`
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    width: 100%;
    height: 100vh;
    background-color: #9b4819;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: right 0.3s ease-in-out;
  }
`

const NavList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const NavItem = styled.li`
  margin: 0 1rem;

  @media (max-width: 768px) {
    margin: 1rem 0;
  }
`

const NavLink = styled(Link)`
  // Use Link instead of a
  font-family: 'Radley', sans-serif;
  color: #faf3e9;
  text-decoration: none;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    color: #ffffff;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #faf3e9;
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
`

const BurgerButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: #faf3e9;
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    display: block;
    position: ${({ isOpen }) => (isOpen ? 'fixed' : 'static')};
    top: 1rem;
    right: 2rem;
    z-index: 1001;
  }
`

const HashNavLink = styled(HashLink)`
  font-family: 'Radley', sans-serif;
  color: #faf3e9;
  text-decoration: none;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    color: #ffffff;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #faf3e9;
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
`

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <HeaderContainer>
      <NavLink to="/" onClick={() => setIsMenuOpen(false)}>
        <LogoContainer>
          <Logo src="/logo-mel.png" alt="Mel'Office Logo" />
          <LogoText>Mel'Office</LogoText>
        </LogoContainer>
      </NavLink>

      <BurgerButton onClick={toggleMenu} isOpen={isMenuOpen}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </BurgerButton>
      <NavMenu isOpen={isMenuOpen}>
        <NavList>
          <NavItem>
            <NavLink to="/" onClick={() => setIsMenuOpen(false)}>
              Accueil
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/nos-forfaits" onClick={() => setIsMenuOpen(false)}>
              Nos Forfaits
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/nos-valeurs" onClick={() => setIsMenuOpen(false)}>
              Nos Valeurs
            </NavLink>
          </NavItem>
          <NavItem>
            <HashNavLink to="/#contact" onClick={() => setIsMenuOpen(false)}>
              Contact
            </HashNavLink>
          </NavItem>
        </NavList>
      </NavMenu>
    </HeaderContainer>
  )
}

export default Header
