import React from 'react'
import styled from 'styled-components'

const FormationsContainer = styled.section`
  background-color: #faf3e9;
  padding: 8rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
`

const FormationsContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`

const Title = styled.h1`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 3.5rem;
  margin-bottom: 1rem;
  line-height: 1.2;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: #ea9d6c;
    margin: 1rem auto 0;
  }
`

const Subtitle = styled.p`
  font-family: 'Radley', sans-serif;
  color: #ea9d6c;
  font-size: 1.75rem;
  margin-bottom: 3rem;
`

const TrainingList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`

const TrainingItem = styled.div`
  background-color: #ffffff;
  border: 2px solid #9b4819;
  border-radius: 20px;
  padding: 2rem;
  text-align: left;
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 1.1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }

  &::before {
    content: '';
    position: absolute;
    top: -50px;
    right: -50px;
    width: 150px;
    height: 150px;
    background-color: rgba(234, 157, 108, 0.2);
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  &:hover::before {
    top: -70px;
    right: -70px;
    width: 200px;
    height: 200px;
    background-color: rgba(234, 157, 108, 0.3);
  }
`

const TrainingTitle = styled.h2`
  font-size: 1.75rem;
  margin-bottom: 1rem;
  color: #9b4819;
  position: relative;
  padding-bottom: 10px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: #ea9d6c;
  }
`

const TrainingDetails = styled.p`
  margin: 1.5rem 0;
`

const Button = styled.a`
  display: inline-block;
  margin-top: 1rem;
  padding: 0.8rem 1.5rem;
  background-color: #9b4819;
  color: #faf3e9;
  font-family: 'Radley', sans-serif;
  font-size: 1rem;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #ea9d6c;
    transform: translateY(-3px);
  }
`

const Formations = () => {
  return (
    <FormationsContainer>
      <FormationsContent>
        <Title>Nos Formations</Title>
        <Subtitle>
          Améliorez vos compétences avec nos formations spécialisées
        </Subtitle>
        <TrainingList>
          <TrainingItem>
            <TrainingTitle>
              Formation en outils de gestion et d'organisation
            </TrainingTitle>
            <TrainingDetails>
              <strong>Objectif :</strong> Apprendre à utiliser des outils comme
              Trello, Asana ou Notion.
            </TrainingDetails>
            <TrainingDetails>
              <strong>Public :</strong> Professionnels et particuliers.
            </TrainingDetails>
            <TrainingDetails>
              <strong>Durée :</strong> 1 jour.
            </TrainingDetails>
            <Button href="#contact">Contactez-nous</Button>
          </TrainingItem>
          <TrainingItem>
            <TrainingTitle>Formation au développement personnel</TrainingTitle>
            <TrainingDetails>
              <strong>Objectif :</strong> Mieux se connaître et travailler sur
              ses objectifs personnels et professionnels.
            </TrainingDetails>
            <TrainingDetails>
              <strong>Public :</strong> Particuliers.
            </TrainingDetails>
            <TrainingDetails>
              <strong>Durée :</strong> 1 ou 2 jours.
            </TrainingDetails>
            <Button href="#contact">Contactez-nous</Button>
          </TrainingItem>
          <TrainingItem>
            <TrainingTitle>
              Ateliers pratiques (ex : gestion des emails)
            </TrainingTitle>
            <TrainingDetails>
              <strong>Objectif :</strong> Apprendre à gérer sa boîte mail pour
              gagner en efficacité.
            </TrainingDetails>
            <TrainingDetails>
              <strong>Public :</strong> Professionnels et particuliers.
            </TrainingDetails>
            <TrainingDetails>
              <strong>Durée :</strong> 1/2 jour.
            </TrainingDetails>
            <Button href="#contact">Contactez-nous</Button>
          </TrainingItem>
          <TrainingItem>
            <TrainingTitle>
              Formation à l'utilisation des outils de bureautique
            </TrainingTitle>
            <TrainingDetails>
              <strong>Objectif :</strong> Maîtriser les logiciels comme Word,
              Excel, PowerPoint, etc.
            </TrainingDetails>
            <TrainingDetails>
              <strong>Public :</strong> Professionnels et particuliers.
            </TrainingDetails>
            <TrainingDetails>
              <strong>Durée :</strong> 1 à 2 jours.
            </TrainingDetails>
            <Button href="#contact">Contactez-nous</Button>
          </TrainingItem>
        </TrainingList>
      </FormationsContent>
    </FormationsContainer>
  )
}

export default Formations
