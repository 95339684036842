import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import Home from './pages/Home'
import Valeurs from './pages/Valeurs'
import NosForfaits from './pages/NosForfaits'
import ScrollToTop from './components/ScrollToTop'
import MentionsLegales from './pages/Mentions-Legales'
import ConditionsGeneralesVente from './pages/ConditionsGeneralesVente'
import NotFound from './pages/404'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Radley', sans-serif;
    background-color: #F5E6D3;
  }
`

function App() {
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="nos-valeurs" element={<Valeurs />} />
        <Route path="nos-forfaits" element={<NosForfaits />} />
        <Route path="mentions-legales" element={<MentionsLegales />} />
        <Route
          path="conditions-generales-vente"
          element={<ConditionsGeneralesVente />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
}

export default App
