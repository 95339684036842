import React from 'react'
import Header from '../components/Header'
import Hero from '../components/Hero'
import Transition from '../components/Transition'
import WhyAssistant from '../components/WhyAssistant'
import About from '../components/About'
import Formations from '../components/Formations'
import Specialites from '../components/Specialites'
import Experience from '../components/Experience'
import Testimonials from '../components/Testimonials'
import QuoteRequest from '../components/QuoteRequestContainer'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import Partenariats from '../components/Partenariats'
import { Helmet } from 'react-helmet'

function Home() {
  return (
    <>
      <Helmet>
        <title>
          Mélanie Blaise - Assistante Freelance Professionnelle à Épervans
        </title>
        <meta
          name="description"
          content="Mélanie Blaise, assistante freelance avec 13 ans d'expérience, basée à Épervans (71380). Optimisez votre productivité et libérez votre temps. Services sur mesure pour entrepreneurs et entreprises."
        />
      </Helmet>
      <Header />
      <Hero />
      <QuoteRequest />
      <About />
      <WhyAssistant />
      <Specialites />
      <Transition />
      <Formations />
      <Experience />
      <Testimonials />
      <Partenariats />
      <Transition />
      <Contact />
      <Footer />
    </>
  )
}

export default Home
