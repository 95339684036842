import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const QuoteRequestContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(155, 72, 25, 0.9);
  color: #faf3e9;
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 100%;
  box-sizing: border-box;
  animation: ${slideUp} 0.5s ease-out;

  @media (min-width: 768px) {
    bottom: 20px;
    right: 20px;
    left: auto;
    border-radius: 8px;
    max-width: 350px;
    background-color: rgba(155, 72, 25, 0.8);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
`

const Message = styled.p`
  font-family: 'Radley', sans-serif;
  font-size: 0.9rem;
  margin: 0;
  margin-right: 1rem;
  flex: 1;
`

const CTAButton = styled.a`
  background-color: #ea9d6c;
  color: #9b4819;
  font-family: 'Radley', sans-serif;
  font-size: 0.9rem;
  padding: 0.4rem 1rem;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-block;

  &:hover {
    background-color: #faf3e9;
    color: #9b4819;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
`

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #faf3e9;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const QuoteRequest = () => {
  const [isOpen, setIsOpen] = useState(true)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <QuoteRequestContainer>
      {isOpen && (
        <>
          <Message>Besoin d'un devis personnalisé ?</Message>
          <CTAButton href="#contact">Demandez un devis</CTAButton>
        </>
      )}
      <ToggleButton onClick={toggleOpen}>
        {isOpen ? <FaChevronDown /> : <FaChevronUp />}
      </ToggleButton>
    </QuoteRequestContainer>
  )
}

export default QuoteRequest
