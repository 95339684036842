import React from 'react'
import styled from 'styled-components'
import { FaChevronRight } from 'react-icons/fa'

import commercialImage from '../2.webp'
import adminImage from '../1.webp'
import comptabiliteImage from '../3.webp'

const SpecialitesContainer = styled.section`
  background-color: #faf3e9;
  padding: 8rem 2rem;
`

const Title = styled.h2`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 4rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 50px;
    height: 3px;
    background-color: #ea9d6c;
    transform: translateX(-50%);
  }
`

const SpecialitesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
`

const SpecialiteCard = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  width: 320px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const SpecialiteImage = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${SpecialiteCard}:hover & {
    transform: scale(1.1);
  }
`

const SpecialiteContent = styled.div`
  padding: 2rem;
`

const SpecialiteTitle = styled.h3`
  font-family: 'Radley', sans-serif;
  color: #9b4819;
  font-size: 1.75rem;
  margin-bottom: 1rem;
  text-align: center;
`

const SpecialiteList = styled.ul`
  list-style-type: none;
  padding: 0;
`

const SpecialiteItem = styled.li`
  font-family: 'Radley', sans-serif;
  color: #333333;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateX(10px);
  }

  svg {
    margin-right: 8px;
    color: #9b4819;
  }
`

const specialites = [
  {
    title: 'Commercial',
    image: commercialImage,
    items: [
      'Gestion clientèle',
      'Gestion fournisseurs',
      'Marketing',
      'Logo',
      'Action de communication',
      'Service Client',
    ],
  },
  {
    title: 'Administratif / RH',
    image: adminImage,
    items: [
      'Prise de rendez-vous',
      'Rédaction courrier/mail',
      'Gestion des heures',
      'Business Plan',
      'Démarches en ligne',
      'Montage et suivi de dossier',
      'Fiche de poste',
      'Gestion du personnel',
      'Procédure',
    ],
  },
  {
    title: 'Comptabilité',
    image: comptabiliteImage,
    items: ['Facture', 'Devis', 'Bon de commande', 'Pointage et rapprochement'],
  },
]

const Specialites = () => {
  return (
    <SpecialitesContainer>
      <Title>Nos Spécialités</Title>
      <SpecialitesList>
        {specialites.map((specialite, index) => (
          <SpecialiteCard key={index}>
            <SpecialiteImage src={specialite.image} alt={specialite.title} />
            <SpecialiteContent>
              <SpecialiteTitle>{specialite.title}</SpecialiteTitle>
              <SpecialiteList>
                {specialite.items.map((item, itemIndex) => (
                  <SpecialiteItem key={itemIndex}>
                    <FaChevronRight />
                    {item}
                  </SpecialiteItem>
                ))}
              </SpecialiteList>
            </SpecialiteContent>
          </SpecialiteCard>
        ))}
      </SpecialitesList>
    </SpecialitesContainer>
  )
}

export default Specialites
